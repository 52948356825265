<template>
  <div id="AboutUs">
    <Hero src="../../../assets/about-us.jpg">
      <Headline>
        {{ $t('headline') }}
      </Headline>
      <Row>
        <Column :lg="6">
          <h2 v-html="$t('title')" />
        </Column>
        <Column :lg="6">
          <div class="hero-side-text">
            <p>{{ $t('side') }}</p>
          </div>
        </Column>
      </Row>
      <HeroScrollDown hash="#philosophy" slot="outside" />
    </Hero>

    <div id="reach">
      <SectionOurReach />
    </div>
    <div id="focus">
      <SectionFocus />
    </div>
    <div id="business-model">
      <SectionBusinessModel />
    </div>
    <div id="philosophy">
      <SectionPhilosophy />
    </div>
    <!-- <div id="team">
      <SectionTeam />
    </div> -->
    <div id="team">
      <SectionTeamAll />
    </div>
    <div id="group">
      <SectionGroup />
    </div>
    <!-- <SectionVideo2020 /> -->
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
import SectionBusinessModel from 'views/components/SectionBusinessModel'
import SectionFocus from './components/SectionFocus'
// import SectionKeySuccessStory from './components/SectionKeySuccessStory'
import SectionGroup from './components/SectionGroup'
import SectionOurReach from 'views/components/SectionOurReach'
import SectionPhilosophy from './components/SectionPhilosophy'
// import SectionTeam from './components/SectionTeam'
import SectionTeamAll from './components/SectionTeamAll'
// import SectionVideo2020 from './components/SectionVideo2020'

export default {
  mixins: [
    Meta(require('@/assets/social/about-us.jpg')),
  ],
  components: {
    Hero,
    HeroScrollDown,
    SectionBusinessModel,
    SectionFocus,
    // SectionKeySuccessStory,
    SectionGroup,
    SectionOurReach,
    SectionPhilosophy,
    // SectionTeam,
    SectionTeamAll,
    // SectionVideo2020,
  },
  computed: getters('locale'),
  methods: actions('FETCH_MEMBER'),
  created() {
    this.LOAD(this.FETCH_MEMBER())
  },
}
</script>

<style lang="scss">
#AboutUs {
  .Hero-body {
    @include lg {
      padding-top: 8rem;
    }

    .Headline {
      color: $white;
    }

    .hero-side-text {
      @include lg {
        padding-left: 20%;
      }
    }
  }

  .SectionSplit.career {
    background: $white;

    h2 {
      margin-bottom: 1rem;
    }

    .Btn {
      margin-top: 1.5rem;
    }
  }

  // #SectionTeamAll {
  //   padding-top: 0;
  // }
}
</style>

<i18n>
{
  "de": {
    "headline": "Über uns",
    "meta": {
      "description": "Die Sun Investment Group (SIG) ist eine Entwicklungs- und Investmentmanagementgesellschaft, die sich darauf konzentriert, in der sich schnell entwickelnden Solarenergiebranche konstante Qualität zu liefern",
      "title": "Über uns"
    },
    "side": "Die Sun Investment Group (SIG) ist eine Entwicklungs- und Investmentmanagementgesellschaft, die sich darauf konzentriert, in der sich schnell entwickelnden Solarenergiebranche konstante Qualität zu liefern",
    "title": "Changing the<br> game of solar"
  },
  "en": {
    "headline": "About us",
    "meta": {
      "description": "Sun Investment Group (SIG) is a renewable energy developer, focused on delivering steady quality in the rapidly evolving solar energy industry.",
      "title": "About Us"
    },
    "side": "By developing top-quality renewable energy generating capacity, we focus on energy transition and creating value for investors and the planet.",
    "title": "Committed to energy transition<br>and a sustainable planet"
  },
  "it": {
    "headline": "",
    "meta": {
      "description": "",
      "title": ""
    },
    "side": "",
    "title": ""
  },
  "lt": {
    "headline": "Apie mus",
    "meta": {
      "description": "Sun Investment Group (SIG) yra saulės energetikos projektų vystymo įmonių grupė, užtikrinanti stabilią kokybę greitai kintančiame sektoriuje.",
      "title": "Apie mus"
    },
    "side": "Sun Investment Group (SIG) yra saulės energetikos projektų vystymo įmonių grupė, užtikrinanti stabilią kokybę greitai kintančiame sektoriuje.",
    "title": "Committed to energy transition<br>and a sustainable planet"
  },
  "pl": {
    "headline": "O nas",
    "meta": {
      "description": "Sun Investment Group (SIG) zajmuje się rozwojem i zarządzaniem inwestycjami w elektrownie słoneczne. Celem spółki jest tworzenie wysokiej jakości rozwiązań w dynamicznie rozwijającej się branży energetyki solarnej.",
      "title": "O nas"
    },
    "side": "Sun Investment Group (SIG) zajmuje się rozwojem i zarządzaniem inwestycjami w elektrownie słoneczne. Celem spółki jest tworzenie wysokiej jakości rozwiązań w dynamicznie rozwijającej się branży energetyki solarnej.",
    "title": "Zdobywamy pozycję lidera<br>w branży energetyki słonecznej"
  }
}
</i18n>
